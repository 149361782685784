// extracted by mini-css-extract-plugin
export var formSections = "colson-form-request-module--formSections--51WvU";
export var section = "colson-form-request-module--section--V4dOA";
export var fields = "colson-form-request-module--fields--qB-j8";
export var selectOptions = "colson-form-request-module--selectOptions--XKBK7";
export var submitClub = "colson-form-request-module--submitClub--VPz63";
export var emptyBox = "colson-form-request-module--emptyBox--bFpCW";
export var tip = "colson-form-request-module--tip--4JCrj";
export var ads_form = "colson-form-request-module--ads_form--c1kn4";
export var align_left = "colson-form-request-module--align_left--VTHDZ";
export var mandatory = "colson-form-request-module--mandatory--crTy7";
export var divider = "colson-form-request-module--divider--81fVD";
export var radio_container = "colson-form-request-module--radio_container--mex7R";
export var radio_label = "colson-form-request-module--radio_label--zpgBo";
export var info = "colson-form-request-module--info--AbKAo";
export var title = "colson-form-request-module--title--ivaRo";
export var subTitle = "colson-form-request-module--subTitle--rv7Uj";
export var radios = "colson-form-request-module--radios--STO8R";
export var other = "colson-form-request-module--other--TAJeJ";
export var conditions = "colson-form-request-module--conditions--Gbvjx";
export var errorBox = "colson-form-request-module--errorBox--0b8q6";
export var linkTerms = "colson-form-request-module--linkTerms--eoscI";
export var terms = "colson-form-request-module--terms--gH3vY";
export var fullname = "colson-form-request-module--fullname--C-mBE";
export var submit = "colson-form-request-module--submit--9gKb7";
export var success_view = "colson-form-request-module--success_view--mBtVl";
export var text = "colson-form-request-module--text--Bm+zj";
export var btn_container = "colson-form-request-module--btn_container--ndLoo";
export var go_home = "colson-form-request-module--go_home--GFdcW";